body {
  margin: 0;
  font-size: 16;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: inherit;
  font-size: inherit;
  font-weight: inherit;
}

li {
  list-style-type: none;
}

ul {
  margin: 0;
  padding: 0;
}

.Toastify__toast--default {
    background: #fff;
    color: #aaa;
 }
  .Toastify__toast--info {
    background: #3385ff;
 }
  .Toastify__toast--success {
    background: #219653;
 }
  .Toastify__toast--warning {
    background: #e45;
 }
  .Toastify__toast--error {
    background: #e45;
 }
